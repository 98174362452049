import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AssetItem, AssetType, Maybe } from '@designage/gql';
import { ResizeCropMethodURIs } from '@desquare/enums';
import { IZoneResolution } from '@desquare/interfaces';
import { getOptimizedUrl } from '@desquare/utils';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-asset-item-thumbnail',
  templateUrl: './asset-item-thumbnail.component.html',
  styleUrls: ['./asset-item-thumbnail.component.scss'],
})
export class AssetItemThumbnailComponent implements OnInit {
  @Input() assetItem: Maybe<AssetItem>;
  assetType = AssetType;

  constructor() {}

  ngOnInit(): void {}

  private getUri(resolution?: IZoneResolution): Maybe<string> {
    if (!this.assetItem) return '';
    switch (this.assetItem.type) {
      case AssetType.Video: {
        const videoThumbnail = `${this.uri?.substring(
          0,
          this.uri.lastIndexOf('.')
        )}.webp`;
        return getOptimizedUrl(AssetType.Image, videoThumbnail, resolution);
      }
      case AssetType.Image: {
        const resizeCropMethod =
          this.assetItem.__typename === 'ImageAsset'
            ? this.assetItem.resizeCropMethod?.uri
            : ResizeCropMethodURIs.PAD_URI;
        return this.assetItem && 'uri' in this.assetItem && this.assetItem.uri
          ? getOptimizedUrl(
              AssetType.Image,
              this.assetItem.uri,
              resolution,
              resizeCropMethod
            )
          : '';
      }

      default:
        return null;
    }
  }

  get uri() {
    return this.assetItem && 'uri' in this.assetItem ? this.assetItem.uri : '';
  }
  get thumbnailUrl() {
    if (!this.assetItem) return;

    switch (this.assetItem.type) {
      case AssetType.Image:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });

      case AssetType.Video:
        return this.getUri({
          resolution: '',
          width: 0,
          height: 200,
          aspect: 0,
          orientation: '',
          default: false,
        });

      default:
        return '';
    }
  }
}
