import { Maybe } from '@designage/gql';
import moment from 'moment';

/**
 *
 * @returns a local date string (no timezone)
 */
export function getTodayDateString() {
  return new Date().toDateString();
}

/**
 * recognize date format separator
 * @param input YYYY/MM/DD, YYYY-MM-DD, YYYY MM DD
 * @returns
 */
export function getDateSeparator(input: string) {
  const possibleSeparators = ['/', '-', ' '];
  for (const sep of possibleSeparators) {
    if (input.indexOf(sep) > 0) {
      return sep;
    }
  }
  return 'unknown';
}

export function getDateValue(input: string | moment.Moment): Maybe<Date> {
  if (typeof input === 'string' && input !== '') {
    /*const sep = getDateSeparator(input);
    const parts = input.split(sep)
    if (sep.length>1 || parts<3) {
      console.error('getDate: unknown date format')
      return null;
    }

    const date = new Date(+parts[0], +parts[1], +parts[2]);*/
    return new Date(input);
  } else if (moment.isMoment(input)) {
    return moment(input).local().toDate();
  } else {
    return null;
  }
}

/** transform yyyy-mm-ddThh:nn[]:Ss] to Date */
export function getDateTimeValue(
  date: Maybe<string>,
  time: Maybe<String>,
): Maybe<Date> {
  console.log(`getDateTimeValue d='${date}' t='${time}'`);

  if (date && date !== '') {
    try {
      const sep = getDateSeparator(date);
      const ret = new Date();
      const [y, m, d] = date.split(sep);
      ret.setFullYear(+y, +m, +d);
      ret.setHours(0);
      ret.setMinutes(0);
      ret.setSeconds(0);
      ret.setMilliseconds(0);
      if (time && time !== '') {
        const [hh, mm, ss] = time.split(':');
        ret.setMinutes(+hh);
        ret.setSeconds(+mm);
        ret.setMilliseconds(+ss);
      }
      console.log('getDateTimeValue >> ', ret);
      return ret;
    } catch {
      // nothing to do
    }
  }
  return null;
  /*
  const values = input?.split('T') || [];
  if (values.length) {
    const date = getDateValue(values[0]);
    if (date) {
      const timeString = values.length > 1 ? values[1] : '00:00:00';
      const timeParts = timeString.split(':');
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      if (timeParts.length > 1) {
        date.setHours(+timeParts[0]);
        date.setMinutes(+timeParts[1]);
      }
    }
  }
  return null; */
}

export function toGqlDateInput(dateStr: string) {}
