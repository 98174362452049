<img
  *ngIf="
    assetItem?.type === assetType.Video || assetItem?.type === assetType.Image
  "
  class="preview"
  [src]="thumbnailUrl"
/>

<!--
  <ng-container
  
  >
</ng-container>
-->
